import React, { useEffect } from "react";
import whatsImg from "../../images/ws.png";
import "./Admin.css";
import messengerImg from "../../images/orginalMessenger.png";
import fbImg from "../../images/orginalFb.png";
import { useState } from "react";
import Navigation from "../Navigation/Navigation";
import LogoSection from "../LogoSection/LogoSection";
import WebsiteLink from "../WebsiteLink/WebsiteLink";

const Admin = () => {
  const [admin, setAdmin] = useState([]);
  useEffect(() => {
    fetch("./Admin.JSON")
      .then((res) => res.json())
      .then((data) => setAdmin(data));
  }, []);
  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          {/* logo section  */}
          <LogoSection />

          {/* navigation bar  */}
          <Navigation />

          <div className="customar-service-list">
            <h4>9WICKETS Admin List </h4>
          </div>
          <div className="customer-service-notice">
            হোয়াটসাপ ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ বা লেনদেন করা যাবে
            না এবং করলে তা গ্রহনযোগ্য হবে না
          </div>
          {/* <div className="customar-service-list">
            <p className="tabel-details">
              এডমিন এর সাথে হোয়াটসাপ ও মেসেঞ্জার ব্যাতিত অন্য কোন এপ এর মাধ্যমে
              যোগাযোগ যাবে না{" "}
            </p>
          </div> */}
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">PHONE APP LINK</th>
                  <th scope="col">PHONE NUMBER</th>
                </tr>
              </thead>
              <tbody className="table_color">
                {admin.map((customar) => (
                  <>
                    <tr key={customar.id}>
                      <td>{customar.type}</td>
                      <td>{customar.name}</td>
                      <td>
                        <div className="socail-icon">
                          {/* <a href={customar?.facebook}>
                            <img
                              src={fbImg}
                              id="fb-icon"
                              className="img-fluid icon-img"
                              alt="facebook"
                            />
                          </a>

                          <a href={customar.messenger}>
                            <img
                              id="agent_linkm"
                              src={messengerImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a> */}
                          <a href={customar.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td>{customar.number}</td>
                    </tr>
                    {/* <tr>
                      <td colSpan="4" className="table_down_text">
                        <p className="tabel-details">
                          (বি: দ্রা:)এজেন্ট দের সাথে হোয়াটসাপ ও মেসেঞ্জার
                          ব্যাতিত অন্য কোন মাধ্যমে যোগাযোগ ও লেনদেন করা যাবে না
                          সুতরাং করলে কোম্পানি তার দায়ভার নিবে না।
                        </p>
                      </td>
                    </tr> */}
                  </>
                ))}
              </tbody>
            </table>
          </div>
          {/* <WebsiteLink /> */}
          {/* <div className="tabel-description">
                        <p>* এজেন্ট ও বেট স্বম্পর্কিত কোন অভিযোগ এর জন্য "Admin" সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।</p>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Admin;
