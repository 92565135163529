import React, { useState, useEffect } from "react";
import "./CustomarService.css";
import whatsImg from "../../../images/ws.png";
import messengerImg from "../../../images/messengers-removebg-preview-removebg-preview (1).png";
import LogoSection from "../../LogoSection/LogoSection";
import Navigation from "../../Navigation/Navigation";
import WebsiteLink from "../../WebsiteLink/WebsiteLink";

const CustomarService = () => {
  const [customarService, setCustomarService] = useState([]);

  useEffect(() => {
    fetch("./CustomarService.JSON")
      .then((res) => res.json())
      .then((data) => setCustomarService(data));
  }, []);
  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          {/* logo section  */}
          <LogoSection />

          {/* navigation bar  */}
          <Navigation />

          <div className="customar-service-list">
            <h4>9WICKETS CUSTOMER SERVICE LIST (TIME 10AM TO 10PM)</h4>
          </div>
          <div className="customer-service-notice">
            কাস্টমার সার্ভিসে হোয়াটসাপ ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ
            যাবে না এবং কাউকে আপনার একাউন্ট এর পাসোয়ার্ড দিবেন না
          </div>
          <div className="customar-service-list"></div>
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">PHONE APP LINK</th>
                  <th scope="col">PHONE NUMBER</th>
                </tr>
              </thead>
              <tbody className="table_color">
                {customarService.map((customar) => (
                  <>
                    <tr key={customar.id}>
                      <td>{customar.type}</td>
                      <td>{customar.name}</td>
                      <td>
                        <div className="socail-icon">
                          {/* <a href={customar.messenger}>
                            <img
                              id="agent_linkm"
                              src={messengerImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a> */}
                          <a href={customar.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td>{customar.number}</td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="table_down_text">
                        <p className="tabel-details">
                          কাস্টমার সার্ভিসে হোয়াটসাপ ব্যাতিত অন্য কোন এপ এর
                          মাধ্যমে যোগাযোগ যাবে না এবং কাউকে আপনার একাউন্ট এর
                          পাসোয়ার্ড দিবেন না
                        </p>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>

          {/* <div className="tabel-description">
                        <p>কাষ্টমার সার্ভিস এর কাউকেই আপনার পাসওয়ার্ড দিবেন না। শুধু আপনার LC247 এর ইউজার নেম দিবেন - যদি প্রয়োজন হয়।</p>
                        <ul>
                            <li>*** স্বাধারন তথ্য জানার জন্য "General Inquiry" এর সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।</li>
                            <li>*** বেট বিষয়ক তথ্য জানার জন্য "General Inquiry" এর সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।</li>
                            <li>*** পয়েন্ট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CUSTOMER SERVICE" সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।</li>
                            <li>*** বেট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CUSTOMER SERVICE" সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।</li>
                            <li>*** এজেন্ট স্বম্পর্কিত কোন অভিযোগ এর জন্য "CS MANAGER" সাথে যোগাযোগ করার জন্য অনুরোধ করা হল।
                            </li>
                        </ul>
                    </div> */}
          {/* <WebsiteLink /> */}
        </div>
      </div>
    </div>
  );
};

export default CustomarService;
