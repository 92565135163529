import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import iconImg from "../../images/Info.png";
import whatsImg from "../../images/ws.png";
import messengerImg from "../../images/orginalMessenger.png";
import LogoSection from "../../Component/LogoSection/LogoSection";
import Navigation from "../../Component/Navigation/Navigation";
import WebsiteLink from "../../Component/WebsiteLink/WebsiteLink";
import fbImg from "../../images/orginalFb.png";

const Header = () => {
  const [superAgent, setSuperAgent] = useState([]);

  useEffect(() => {
    fetch("./OnlineMasterAget.JSON")
      .then((res) => res.json())
      .then((data) => {
        setSuperAgent(data);
      });
  }, []);

  let arrayM = Math.floor(Math.random() * superAgent.length + 1);
  const findData = superAgent.find((ele) => ele?.id === arrayM);

  return (
    <div className="main-section" id="home-page">
      <div className="content-section">
        {/* logo section  */}
        <LogoSection />

        {/* navigation bar  */}
        <Navigation />

        {/* quick master  */}

        <div className="quick-master">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1>QUICK MASTER AGENT NUMBER:</h1>
          </div>

          <div className="quick-master-description">
            <div className="table table-bordered backround_overlay">
              <table className="data">
                <tbody>
                  {
                    <tr className="quick-master-row" key={findData?.id}>
                      <td>AGENT ID :</td>
                      <td>{findData?.id}</td>
                      <td>
                        <div className="socail-icon">
                          {/* <a href={findData?.facebook}>
                            <img
                              src={fbImg}
                              id="fb-icon"
                              className="img-fluid icon-img"
                              alt="facebook"
                            />
                          </a>

                          <a href={findData?.messenger}>
                            <img
                              id="agent_linkm"
                              src={messengerImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a> */}

                          <a href={findData?.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td>{findData?.number}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* acount info  */}

        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1>কিভাবে একাউন্ট খুলবেন ?</h1>
          </div>
          <div className="account-info-content">
            <div className="main-content">
              <p>
                {" "}
                9Wickets তে একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট
                খুলতে হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে।
                আপনি যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন তার সাথেই সব সময়
                লেনদেন করতে হবে। ঠিক কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার
                সাথে লেনদেন করবেন তার বুঝতে হলে আপনার নিম্বের তথ্য গুলো পড়া
                জরুরী।
              </p>
            </div>
          </div>
        </div>

        {/* agent list  */}

        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1>এজেন্ট লিস্ট ?</h1>
          </div>
          <div className="account-info-content">
            <div className="main-content">
              <p>
                একাউন্ট খুলতে নিম্বের অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন। এজেন্ট
                লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র হোয়াটসাপ এর
                মাধ্যমে যোগাযোগ করতে হবে। হোয়াটসাপ ছাড়া অন্য কোন মাধ্যমে যোগাযোগ
                করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে না। হোয়াটসাপ এ যোগাযোগ
                করতে হলে এজেন্ট লিস্টে হোয়াটসাপ আইকন উপরে ক্লিক করুন অথবা ফোন
                নাম্বার টি মোবাইলে সেভ করে তাকে হোয়াটসাপ এ মসেজ পাঠাতে পারবেন।
                হোয়াটসাপ এপ টি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল
                প্লে থেকে ইন্সটল করে নিন।
                <br />
                <Link to="/onlineagent" className="text-center">
                  অনলাইন মাষ্টার এজেন্ট লিস্টঃ
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>

        {/* Agent different */}

        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1>এজেন্ট কয় প্রকার ?</h1>
          </div>

          <div className="account-info-content">
            <div className="main-content">
              <p>
                {" "}
                <Link to="/superagent">অনলাইন সুপার এজেন্ট লিস্টঃ</Link> সুপার
                এজেন্ট রা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট একাউন্ট খুলে দিতে
                পারেন। কোন সুপার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি এডমিন কে
                জানাতে হবে। উপরে মেনু তে এডমিন লিষ্ট দেয়া আছে।
              </p>
              <p>
                <Link to="/onlineagent">অনলাইন মাষ্টার এজেন্ট লিস্টঃ</Link>{" "}
                অনলাইন মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে
                পারেন। কোন মাষ্টার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি সুপার
                এজেন্ট এর কাছে অভিযোগ করতে হবে।
              </p>
              <p>
                {" "}
                <a
                  href="https://facebook.com/groups/velki365.liveagent/"
                  className="text-center"
                >
                  বিস্তারিত জানতে এই লিঙ্ক এ ক্লিক করুন।
                </a>
                লোকাল মাষ্টার এজেন্টঃ এই সব এজেন্ট সাধারনত – নিজের এলাকায় বা
                পরিচিত দের সাথে লেনদেন করে । যারা আগে বাজি ধরিয়ে দিত, তাদের কেই
                মুলত লোকাল এজেন্ট দেয়া হয়েছে। লোকাল এজেন্ট রা অনলাইনে আসে না
                এবং তারা তাদের পরিচয় গোপন রাখতে চায়। লোকাল এজেন্ট দের সাথে
                অনলাইনে কোন ভাবেই লেনদেন করবেন না, আর করে থাকলে তার দায়ভার
                পুরোটাই আপনার।
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* website link  */}
      {/* <WebsiteLink /> */}
    </div>
  );
};

export default Header;
