import React from "react";
import fristLogo from "../../images/logo-wicket7.png";
import secondLogo from "../../images/9 wick logo-01.jpg";

const LogoSection = () => {
  return (
    <>
      <div className="logo-section">
        <div className="logo_content">
          {/* <div className="logo">
          
          </div> */}
          <div className="agent_logo">
            <img src={secondLogo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoSection;
