import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import whatsImg from "../../images/ws.png";
import LogoSection from "../LogoSection/LogoSection";
import Navigation from "../Navigation/Navigation";

const SubAdmin = () => {
  const [subAdmin, setSubAdmin] = useState([]);

  useEffect(() => {
    fetch("./SubAdmin.JSON")
      .then((res) => res.json())
      .then((data) => setSubAdmin(data));
  }, []);
  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          {/* logo section  */}
          <LogoSection />

          {/* navigation bar  */}
          <Navigation />

          <div className="customar-service-list">
            <h4>9WICKETS SUBADMIN LIST</h4>
          </div>

          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">PHONE APP LINK</th>
                  <th scope="col">PHONE NUMBER</th>
                </tr>
              </thead>
              <tbody>
                {subAdmin.map((admin, index) => (
                  <>
                    <tr
                      key={admin.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#eeeeee" : "#FFF",
                      }}
                    >
                      <td>{admin.type}</td>
                      <td>{admin.name}</td>
                      <td>
                        <div className="socail-icon">
                          <a href={admin.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td>{admin.number}</td>
                    </tr>
                    {/* <tr className="customarAgent">
                      <td colSpan={4}>
                        <p className="tabel-details">
                          হোয়াটসাপ ছাড়া আর কোন এপ ব্যবহার করে না - তাই হোয়াটসাপ
                          ছাড়া আর অন্য কোন এপ এর মাধ্যমে যোগাযোগ করবেন না।
                        </p>
                      </td>
                    </tr> */}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAdmin;
