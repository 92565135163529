import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import iconImg from "../../images/Info.png";
import whatsImg from "../../images/ws.png";
import messngImg from "../../images/orginalMessenger.png";
import fbImg from "../../images/orginalFb.png";
import LogoSection from "../LogoSection/LogoSection";
import Navigation from "../Navigation/Navigation";
import WebsiteLink from "../WebsiteLink/WebsiteLink";

const SuperComplain = () => {
  const [superComplain, setSuperComplain] = useState([]);
  const { superId } = useParams();

  useEffect(() => {
    fetch("/SuperAgentComplain.JSON")
      .then((res) => res.json())
      .then((data) => setSuperComplain(data));
  }, []);

  const findData = superComplain?.filter((ele) => ele?.id == superId);
  console.log(findData[0]);

  return (
    <div className="main-section" id="home-page">
      <div className="content-section">
        {/* logo section  */}
        <LogoSection />

        {/* navigation bar  */}
        <Navigation />

        <div className="account-info">
          <div className="quick-master-title">
            <img src={iconImg} alt="" />
            <h1> {findData[0]?.name} - এডমিন </h1>
          </div>
          <div className="account-info-content" id="agent-list-icon-number">
            <div className="mobile-app-link">
              <h4>MOBILE APP LINK</h4>
              <div className="socail-media-icons">
                <a href={findData[0]?.facebook}>
                  <img
                    src={fbImg}
                    id="facebook-img"
                    className="img-fluid"
                    alt="facebook"
                  />
                </a>
                <a href={findData[0]?.messenger}>
                  <img
                    className="img-fluid"
                    id="messsenger-img"
                    src={messngImg}
                    alt="messenger"
                  />
                </a>
                <a href={findData[0]?.whatsapp}>
                  <img
                    className="img-fluid"
                    id="whatsapp-img"
                    src={whatsImg}
                    alt="whatsapp"
                  />
                </a>
              </div>
            </div>
            <div className="phone-number-secion">
              <h4>PHONE NUMBER</h4>
              <p className="phone-number">{findData[0]?.number}</p>
            </div>
          </div>
        </div>
        {/* <WebsiteLink /> */}
      </div>
    </div>
  );
};

export default SuperComplain;
