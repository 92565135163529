import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./SuperAgent.css";
import whatsImg from "../../images/ws.png";
import messengerImg from "../../images/orginalMessenger.png";
import fbImg from "../../images/orginalFb.png";
import Navigation from "../Navigation/Navigation";
import LogoSection from "../LogoSection/LogoSection";
import WebsiteLink from "../WebsiteLink/WebsiteLink";

const SuperAgent = () => {
  const [superAgent, setSuperAgent] = useState([]);
  const [displayAgent, setDisplayAgent] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch("./SuperAgentList.JSON")
      .then((res) => res.json())
      .then((data) => {
        setSuperAgent(data);
        // setDisplayAgent(data);
      });
  }, []);

  useEffect(() => {
    setDisplayAgent(shuffleArray(superAgent));
  }, [superAgent]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  // input handle onchange
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  // handle search bar
  const handleSearch = (e) => {
    e.preventDefault();
    if (search === " ") {
      console.log("something");
    } else {
      const filterValue = superAgent.filter(
        (onlineAg) =>
          onlineAg.id == search ||
          onlineAg.name.toLowerCase().includes(search.toLowerCase())
      );
      setDisplayAgent(filterValue);
    }
  };

  return (
    <div>
      <div className="main-section">
        <div className="content-section">
          {/* logo section  */}
          <LogoSection />

          {/* navigation bar  */}
          <Navigation />

          {/* search bar  */}
          <form onSubmit={handleSearch} className="search-agent">
            <input
              type="text"
              placeholder="Search agent Id..."
              onChange={handleChange}
              value={search}
            />
            <button type="submit">
              <i className="fa-solid fa-magnifying-glass search-icon"></i>
            </button>
          </form>
          <div className="customar-service-list">
            <h4>9WICKETS Super Agent List</h4>
          </div>
          <div className="customer-service-notice">
            হোয়াটসাপ ব্যাতিত অন্য কোন এপ এর মাধ্যমে যোগাযোগ বা লেনদেন করা যাবে
            না এবং করলে তা গ্রহনযোগ্য হবে না
          </div>
          <div className="customar-service-tabel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">TYPE</th>
                  <th scope="col">NAME</th>
                  <th scope="col">ID NO</th>
                  <th scope="col">PHONE APP LINK</th>
                  <th scope="col">PHONE NUMBER</th>
                  <th scope="col">ADMIN</th>
                </tr>
              </thead>
              <tbody className="table_color">
                {displayAgent.map((superAg, index) => (
                  <>
                    <tr
                      key={superAg.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#eeeeee" : "#FFF",
                      }}
                    >
                      <td>{superAg.type}</td>
                      <td>{superAg.name}</td>
                      <td>{superAg.id} </td>
                      <td>
                        <div className="socail-icon">
                          {/* <a href={superAg.facebook}>
                            <img
                              src={fbImg}
                              id="fb-icon"
                              className="img-fluid icon-img"
                              alt="facebook"
                            />
                          </a>

                          <a href={superAg.messenger}>
                            <img
                              id="agent_linkm"
                              src={messengerImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a> */}

                          <a href={superAg.whatsapp}>
                            <img
                              id="agent_link"
                              src={whatsImg}
                              className="img-fluid icon-img"
                              alt="Whatsapp"
                            />
                          </a>
                        </div>
                      </td>
                      <td className="colorNumber">{superAg.number}</td>
                      <td className="colorNumber">
                        {" "}
                        <Link
                          to={`/superComplain/${superAg.id}`}
                          className="complain-btn"
                        >
                          COMPLAIN
                        </Link>
                      </td>
                    </tr>
                    {/* <tr>
                      <td colSpan="6" className="table_down_text">
                        <p className="tabel-details">
                          (বি: দ্রা:)এজেন্ট দের সাথে হোয়াটসাপ ও মেসেঞ্জার
                          ব্যাতিত অন্য কোন মাধ্যমে যোগাযোগ ও লেনদেন করা যাবে না
                          সুতরাং করলে কোম্পানি তার দায়ভার নিবে না।
                        </p>
                      </td>
                    </tr> */}
                  </>
                ))}
              </tbody>
            </table>
          </div>
          {/* website link  */}
          {/* <WebsiteLink />  */}
        </div>
      </div>
    </div>
  );
};

export default SuperAgent;
