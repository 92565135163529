import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <>
      <div className="navigation-bar">
        <ul>
          <li>
            <NavLink className="nav-menu" to="/home">
              <i className="fa-solid fa-house"></i>{" "}
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-menu" to="/customarservice">
              <i className="fa-duotone fa-question question-icon"></i> SERVICE
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-menu" to="/admin">
              <i class="fa-solid fa-gear"></i> ADMIN
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-menu" to="/subAdmin">
              {" "}
              <i class="fa-solid fa-gear"></i> SUB ADMIN
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-menu" to="/superagent">
              <i class="fa-solid fa-gear"></i> SUPER
            </NavLink>
          </li>
          <li>
            <NavLink className="nav-menu" to="/onlineagent">
              <i class="fa-solid fa-gear"></i> MASTER
            </NavLink>
          </li>
          {/* <li>
            <NavLink className="nav-menu" to="/tutorial">
              <i class="fa-solid fa-gear"></i> OLD/NEW
            </NavLink>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default Navigation;
