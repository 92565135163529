import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Admin from "./Component/Admin/Admin";
import Compalin from "./Component/Compalin/Compalin";
import CustomarService from "./Component/Home/CustomarService/CustomarService";
import Home from "./Component/Home/Home";
import OnlineAgent from "./Component/OnlineAgent/OnlineAgent";
import SubAdmin from "./Component/SubAdmin/SubAdmin";
import SuperAgent from "./Component/SuperAgent/SuperAgent";
import SuperComplain from "./Component/SuperComplain/SuperComplain";
import Tutorial from "./Component/Tutorial/Tutorial";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/home" element={<Home></Home>} />
          <Route
            path="/customarservice"
            element={<CustomarService></CustomarService>}
          />
          <Route path="/admin" element={<Admin></Admin>} />
          <Route path="/subAdmin" element={<SubAdmin></SubAdmin>} />
          <Route path="/superagent" element={<SuperAgent></SuperAgent>} />
          <Route path="/onlineagent" element={<OnlineAgent></OnlineAgent>} />
          <Route
            path="/complain/:agentId"
            element={<Compalin></Compalin>}
          ></Route>
          <Route path="/superComplain/:superId" element={<SuperComplain />} />
          {/* <Route path="/tutorial" element={<Tutorial></Tutorial>}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
